<template>
<div>
	<article class="answer">
		<h1 class="answer__header">
			<img src="~@/assets/img/heatstroke_quiz2022/quiz_title.png" alt="5日でマスター! 熱中症＆夏バテ対策クイズ">
		</h1>

		<section class="answer__body">
			<div class="answer__body--judgment">
				<img v-if="judgmentFlag" src="~@/assets/img/heatstroke_quiz2022/correct.png" alt="正解">
				<img v-else src="~@/assets/img/heatstroke_quiz2022/regret.png" alt="残念">
			</div>

			<div class="answer__body--content">
				<p>解説</p>
				<span v-html="explanationText" />
			</div>

			<div class="answer__body--description">
				<i class="sprite_quiz balloon" />
				<p v-html="clearFlag ? 'クイズは全部回答済みじゃぞ。<br>スタンプをゲットするのじゃ！' : '今日のクイズは回答済みじゃぞ。<br>また明日チャレンジじゃ！'" />
			</div>

			<div v-if="clearFlag" class="answer__body--stamp">
				<ul>
					<li><img src="~@/assets/img/stamp/summer202207_stamp01.png" alt="暑すぎぃ"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp03.png" alt="夏に負けるニャ"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp04.png" alt="どんとこい夏"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp06.png" alt="突然の雨"></li>
				</ul>
				<a href="javascript:void(0)" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stamp`, _stampType: 'summer', _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症＆夏バテ対策クイズリンク22" data-gtm-event-act-label="trackingQuizuAnswerBtn22">
					プレゼントをもらう
				</a>
			</div>

			<p class="answer__body--link" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'heatstroke2022', _backPage: 'top' } })">熱中症＆夏バテ特集にもどる</p>
		</section>

		<aside class="answer__back-button--wrapper">
			<p class="answer__back-button" @click="$router.push({ name: 'Cpsite', query: { id: `${sugotokuContentsId}` } })">TOPにもどる</p>
		</aside>
	</article>
</div>
</template>

<script>

// ライブラリ
import cmnConst from '@/assets/js/constant.js'
import moment from 'moment'

// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_USER_DATA } from '@/store/modules/common/mutation-types'

export default {
	name: 'HeatstrokeQuizAnswer',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			judgmentFlag: false,
			explanationText: '',
			clearFlag: false
		}
	},
	mounted () {
		const answerNo = this.$route.query._answer
		if (!this.userData) {
			this.SET_USER_DATA({
				user_id: this.userId,
				user_token: this.userToken
			}).then(res => {
				console.log('userData', this.userData)
				this.setAnswer(answerNo)
			}).catch(error => {
				console.log('Error SET_USER_DATA: ', error)
			})
		} else {
			this.setAnswer(answerNo)
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['userId', 'userToken', 'userData'])
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_USER_DATA, 'updateWebStorage']),

		// クイズの解説を表示する
		setAnswer (answerNo) {
			const offset = '+09:00'
			const accessDate = moment(new Date()).utcOffset(offset)
			const webStorage = this.userData.teigaku.web_storage && this.userData.teigaku.web_storage.data ? JSON.parse(this.userData.teigaku.web_storage.data) : {}
			if ((Object.keys(webStorage).length === 0 || !webStorage.hasOwnProperty('q1')) || (webStorage.q2 === 0 && accessDate.format('YYYYMMDD') === webStorage.q_answer_date)) {
				// NOTE: クイズ問題が初回または、q1の問題を解答済みで解答日がアクセス日と同じ場合
				this.judgmentFlag = Number(answerNo) === 1
				this.explanationText = '熱中症の予防のためには、服と体の間に風が通りやすいゆったりとした服がおすすめです。また、黒色系の濃い色は熱を吸収しやすいので注意が必要です。'
				if (accessDate.format('YYYYMMDD') !== webStorage.q_answer_date) {
					webStorage.q1 = 1
					webStorage.q2 = 0
					webStorage.q3 = 0
					webStorage.q4 = 0
					webStorage.q5 = 0
					webStorage.q_answer_date = accessDate.format('YYYYMMDD')
					this.updateStorage(webStorage)
				}
			} else if (webStorage.q2 === 0 || (webStorage.q3 === 0 && accessDate.format('YYYYMMDD') === webStorage.q_answer_date)) {
				// NOTE: q2が未解答または、q2の問題を解答済みで解答日がアクセス日と同じ場合
				this.judgmentFlag = Number(answerNo) === 0
				this.explanationText = '大量に汗をかくと、体内のナトリウムなどの電解質が汗と一緒に流れ出し、体内の電解質のバランス崩れて脱水症状が現れます。この状況での水分補給には塩分が少し含まれている飲料が最適です。'
				if (accessDate.format('YYYYMMDD') !== webStorage.q_answer_date) {
					webStorage.q2 = 1
					webStorage.q_answer_date = accessDate.format('YYYYMMDD')
					this.updateStorage(webStorage)
				}
			} else if (webStorage.q3 === 0 || (webStorage.q4 === 0 && accessDate.format('YYYYMMDD') === webStorage.q_answer_date)) {
				// NOTE: q3が未解答または、q2の問題を解答済みで解答日がアクセス日と同じ場合
				this.judgmentFlag = Number(answerNo) === 1
				this.explanationText = '夏バテの原因のひとつは自律神経の乱れ。湯船につかることで、血行が良くなり、体の筋肉がほぐれ、自律神経の乱れの改善につながります。'
				if (accessDate.format('YYYYMMDD') !== webStorage.q_answer_date) {
					webStorage.q3 = 1
					webStorage.q_answer_date = accessDate.format('YYYYMMDD')
					this.updateStorage(webStorage)
				}
			} else if (webStorage.q4 === 0 || (webStorage.q5 === 0 && accessDate.format('YYYYMMDD') === webStorage.q_answer_date)) {
				// NOTE: q4が未解答または、q2の問題を解答済みで解答日がアクセス日と同じ場合
				this.judgmentFlag = Number(answerNo) === 1
				this.explanationText = '熱中症を引き起こす条件には「気温が高い」「湿度が高い」「風が弱い」「日差しが強い」があります。頭痛ーるの健康天気指数をチェックして、備えるようにしてくださいね。'
				if (accessDate.format('YYYYMMDD') !== webStorage.q_answer_date) {
					webStorage.q4 = 1
					webStorage.q_answer_date = accessDate.format('YYYYMMDD')
					this.updateStorage(webStorage)
				}
			} else {
				// NOTE: q5が未解答の場合
				this.clearFlag = true
				this.judgmentFlag = Number(answerNo) === 0
				this.explanationText = '夏は冷たいものを飲んだり食べたりすることが多くなりがちですが、胃腸が冷えると胃腸の働きが悪くなり、食欲不振や下痢、便秘などの消化不良を引き起こします。'
				if (accessDate.format('YYYYMMDD') !== webStorage.q_answer_date) {
					webStorage.q5 = 1
					webStorage.q_answer_date = accessDate.format('YYYYMMDD')
					this.updateStorage(webStorage)
				}
			}
		},

		// webStorageのアップデート
		updateStorage (webStorageData) {
			this.updateWebStorage({
				user_id: this.userId,
				user_token: this.userToken,
				data: webStorageData
			}).then(res => {
				// NOTE: userDataの更新
				return this.SET_USER_DATA({
					user_id: this.userId,
					user_token: this.userToken
				})
			}).catch(error => {
				// NOTE: エラーの処理
				console.log(error)
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: このコンポーネント限定の変数（デザインシステム外のため他での利用禁止）
$background-answer: #99DAF1;
$link-text-color: #0859E4;
$background-color-blue: #0071DB;
$background-color-lightgray: #F5F5F5;
$back-button-bg-orange: #FE8F11;
$back-button-shadow-bg-orange: #C9730D;

.answer {
	background: $background-color-lightgray;
	padding-bottom: $spacing-30;
	&__header {
		padding-top: $spacing-10;
		margin: 0;
		box-sizing: border-box;
		img {
			width: 100%;
			vertical-align: bottom;
		}
	}

	&__body {
		padding-bottom: 58px;
		background: $background-answer;
		position: relative;
		&--judgment {
			margin: 0 auto;
			width: 335px;
			img {
				width: 100%;
				vertical-align: bottom;
			}
		}

		&--content {
			width: 335px;
			margin: 0 auto;
			p {
				width: 100%;
				height: 30px;
				margin: 0;
				display: block;
				line-height: 1.875;
				border-radius: 10px 10px 0px 0px;
				background: $background-color-blue;
				font-family: $lp-family-tertiary;
				font-size: $font-size-16;
				text-align: center;
				color: $text-seconday;
			}
			span {
				width: 100%;
				padding: $spacing-10 $spacing-14 $spacing-8 $spacing-14;
				text-align: left;
				font-size: $font-size-16;
				font-family: $lp-family-primary;
				color: $text-primary;
				display: block;
				box-sizing: border-box;
				background: $background-primary;
				border: 2px dashed $background-color-blue;
				border-top: none;
				border-radius: 0px 0px 10px 10px;
			}
		}

		&--description {
			width: 335px;
			margin: $spacing-14 auto;
			position: relative;
			p {
				text-align: left;
				margin: 0;
				position: absolute;
				right: 15px;
				top: 19px;
				width: 220px;
				font-size: $font-size-14;
				font-family: $lp-family-primary;
				color: $text-primary;
			}
		}

		&--stamp {
			width: 335px;
			margin: 0 auto $spacing-20;
			ul {
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				li {
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 78px;
					height: 78px;
					background-color: $background-primary;
					overflow: hidden;
					img {
						display: block;
						width: 100%;
						pointer-events: none;
					}
				}
			}

			a {
				margin: 0 auto;
				padding: $spacing-10 0;
				border-radius: 6px;
				display: block;
				width: 295px;
				color: $text-seconday;
				font-family: $lp-family-secondary;
				font-style: normal;
				font-size: $font-size-18;
				text-align: center;
				text-decoration: none;
				background-color: $back-button-bg-orange;
				box-shadow: 0 3px 0 0 $back-button-shadow-bg-orange;
			}

		}

		&--link {
			margin: 0;
			text-align: center;
			color: $link-text-color;
			font-size: $font-size-16;
			font-family: $lp-family-tertiary;
			text-decoration: underline;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			background-image: url(~@/assets/img/heatstroke_quiz2022/nami_bg.svg),  url(~@/assets/img/heatstroke_quiz2022/nami_bg.svg);
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 28px;
		}
	}

	&__back-button {
		display: block;
		appearance: none;
		color: $text-seconday;
		font-size: $font-size-24;
		background: $button-primary;
		border-radius: 27px;
		width: 74.6667%;
		margin:0 auto;
		padding: $spacing-10 $spacing-10 $spacing-8;
		text-align: center;
		text-decoration: none;

		&--wrapper {
			padding-top: $spacing-20;
		}
	}

	@media (max-width: (374px)) {
		.answer__body {
			&--judgment {
				width: 300px;
			}
			&--content {
				width: 300px;
				p {
					height: 20px;
					font-size: $font-size-12;
				}
				span {
					font-size: $font-size-12;
				}
			}
			&--description {
				width: 300px;
				height: 74px;
				i {
					transform: scale(.895);
					transform-origin: 0 0;
				}
				p {
					right: 15px;
					top: 18px;
					width: 194px;
					font-size: $font-size-12;
				}
			}
			&--stamp {
				width: 300px;
				li {
					width: 68px;
					height: 68px;
				}
				a {
					width: 255px;
					font-size: $font-size-16;
				}
			}
		}
	}
}

.sprite_quiz {
	background-image: url(~@/assets/img/heatstroke_quiz2022/sprite_quiz_popup.png);
	background-size: 355px 288px;
	background-repeat: no-repeat;
	display: block;

	&.balloon {
		width: 335px;
		height: 82px;
		background-position: -10px -196px;
	}
}
</style>
